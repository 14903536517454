import React from 'react';
import { Container, Heading } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import style from './AboutSection.module.scss';
import classNames from 'classnames';

export const AboutSection = () => {
  return (
    <Container className={style.container}>
      <Heading
        size={2}
        className={style.title}
      >
        Про Пласт
      </Heading>

      <Heading
        subtitle
        className={style.subtitle}
      >
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quam corrupti
        inventore voluptatem quos ratione architecto blanditiis in
      </Heading>

      <img
        src="\imgs\img_placeholder.png"
        alt="about plast image"
        className={classNames('pb-5', style.image)}
      />

      <p className={classNames('pb-5', style.body)}>
        <span>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
          itaque excepturi labore laudantium, maxime laborum. Distinctio eos
          quibusdam officia, ipsa ratione a magni sapiente tenetur at ea ducimus
          eius quisquam.
        </span>

        <span>
          Rerum qui ab praesentium veritatis esse. Blanditiis earum inventore
          maiores mollitia. Officia omnis rem nam labore, nulla atque incidunt
          quod provident pariatur harum ratione qui nobis. Adipisci minima
          aspernatur commodi.
        </span>

        <span>
          Rerum qui ab praesentium veritatis esse. Blanditiis earum inventore
          maiores mollitia. Officia omnis rem nam labore, nulla atque incidunt
          quod provident pariatur harum ratione qui nobis. Adipisci minima
          aspernatur commodi.
        </span>
      </p>

      <Link
        className={classNames('button', style.button)}
        to="/about-plast"
        color="primary"
      >
        Дізнайся більше
      </Link>
    </Container>
  );
};
