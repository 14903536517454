import React from 'react';
import { Button } from 'react-bulma-components';
import classNames from 'classnames';

type Props = {
  state: boolean;
  onChange: (vis: boolean) => void;
};

export const ContactUsForm: React.FC<Props> = ({ state, onChange }) => {
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.currentTarget.reset();
    onChange(false);
  }
  return (
    <div
      className={classNames('modal', {
        'is-active': state,
      })}
    >
      <div
        className="modal-background"
        onClick={() => onChange(false)}
      />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Звяжіться з нами</p>
          <button
            className="delete"
            onClick={() => onChange(false)}
          />
        </header>

        <section className="modal-card-body columns is-justify-content-center m-0">
          <div className="column is-12">
            <form
              method="POST"
              // action="/"
              className=" p-5"
              onSubmit={event => handleSubmit(event)}
              id="contavtUsForm"
            >
              <label className="is-block mb-4">
                <span className="is-block mb-2">Ваше ім&apos;я</span>
                <input
                  name="name"
                  type="text"
                  className="input"
                  placeholder="Світлана Іванівна"
                />
              </label>

              <label className="is-block mb-4">
                <span className="is-block mb-2">Ваша Імейл адреса</span>
                <input
                  required
                  name="email"
                  type="email"
                  className="input"
                  placeholder="joe.bloggs@example.com"
                />
              </label>

              <label className="is-block mb-4">
                <span className="is-block mb-2">Повідомлення</span>
                <textarea
                  name="message"
                  className="textarea"
                  rows={3}
                  placeholder="Запишіть ваше повідомлення"
                ></textarea>
              </label>

              <div className="mb-4"></div>
            </form>
          </div>
        </section>
        <footer className="modal-card-foot">
          <Button
            type="submit"
            className="button px-4"
            form="contavtUsForm"
          >
            Відправити
          </Button>
        </footer>
      </div>
    </div>
  );
};
