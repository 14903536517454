export const MAIN_PHOTO_ASSETS = [
  { id: 0, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/1.jpg' },
  { id: 1, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/2.jpg' },
  { id: 2, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/3.jpg' },
  { id: 3, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/4.jpg' },
  { id: 4, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/5.jpg' },
  { id: 5, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/7.jpg' },
  { id: 7, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/8.jpg' },
  { id: 8, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/9.jpg' },
  { id: 9, path: '/imgs/ManagedContent/MainPhotoSwiperAssests/10.jpg' },
];
