import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './MainPhotoSwiper.scss';
import { MAIN_PHOTO_ASSETS } from '../constants';

export const MainPhotoSwiper = () => {
  return (
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={30}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mainPhotoSwiper"
    >
      {MAIN_PHOTO_ASSETS.map(photo => {
        return (
          <SwiperSlide key={photo.id}>
            <img
              src={photo.path}
              alt="main-image"
              className="slider_img"
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
