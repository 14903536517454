import React, { useContext } from 'react';
import style from './App.module.scss';
import '../../styles/main.scss';
import { Outlet } from 'react-router-dom';
import { Section, Container } from 'react-bulma-components';
import classNames from 'classnames';
import { Header, StateContext } from '../../components';

import '../../styles/main.scss';
import { FooterElem } from '../../components/FooterElem';

function App() {
  const { mobileMenuVisible } = useContext(StateContext);

  return (
    <Section
      className={classNames('p-0 is-flex is-flex-direction-column', style.app, {
        [style.menu_visible]: mobileMenuVisible,
      })}
      size="full"
    >
      <Header />

      {!mobileMenuVisible && (
        <Container
          className={classNames('is-flex-grow-1', style.main_container)}
        >
          <Outlet />
        </Container>
      )}

      {!mobileMenuVisible && <FooterElem />}
    </Section>
  );
}

export default App;
