export const LATEST_PHOTO_ASSETS = [
  {
    id: 0,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/1.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 1,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/2.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 2,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/3.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 3,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/4.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 4,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/5.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 5,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/7.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 7,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/8.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 8,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/9.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
  {
    id: 9,
    path: '/imgs/ManagedContent/MainPhotoSwiperAssests/10.jpg',
    title: 'Title Phtoto',
    bodyText:
      'Body text description aboyt the event etc. Some included information etc',
  },
];
