import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './LatestPhotoSwiper.scss';
import { LATEST_PHOTO_ASSETS } from '../constants/LatestPhotoAssets';

export const LatestPhotoSwiper = () => {
  return (
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      speed={600}
      parallax={true}
      modules={[Pagination, Navigation]}
      className="mainPhotoSwiper"
    >
      {LATEST_PHOTO_ASSETS.map(photo => {
        return (
          <SwiperSlide
            key={photo.id}
            className="has-background-primary p-2"
          >
            <div className="slider_img_container">
              <div className="captions">
                <div
                  className="title is-5 mb-1"
                  data-swiper-parallax="-300"
                >
                  {photo.title}
                </div>

                <div
                  className="photo_text has-text-weight-bold"
                  data-swiper-parallax="-100"
                >
                  <p>{photo.bodyText}</p>
                </div>
              </div>

              <img
                src={photo.path}
                alt="main-image"
                className="slider_img"
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
