import React from 'react';
import styles from './NewsSlider.module.scss';

export const NewsSlider = () => {
  return (
    <div className={styles.container}>
      <img
        src="\imgs\Temp\post3.png"
        alt="temp"
        className={styles.image}
      />
    </div>
  );
};
