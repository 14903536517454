import React, { useState } from 'react';
import style from './Calendar.module.scss';
import { Calendar as CalendarReact } from 'react-calendar';
import { Box } from 'react-bulma-components';
import classNames from 'classnames';
import { EventItem } from './EventItem';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const Calendar = () => {
  const [value, onChange] = useState<Value>(new Date());
  return (
    <div
      className={classNames(
        'is-flex is-flex-direction-column is-align-items-center',
        style.container,
      )}
    >
      <Box className={classNames('mb-4 p-0')}>
        <CalendarReact
          onChange={onChange}
          value={value}
        />
      </Box>

      <div
        className={classNames(
          'is-flex is-flex-direction-column is-align-items-center',
          style.events_container,
        )}
      >
        <EventItem
          date={new Date()}
          title="Збори Пласту"
          text="Збори плaстові в церкві на 2му поверсі"
        />

        <EventItem
          date={new Date()}
          title="Збори Пласту"
          text="Збори плaстові в церкві на 2му поверсі"
        />

        <EventItem
          date={new Date()}
          title="Збори Пласту"
          text="Збори плaстові в церкві на 2му поверсі"
        />
      </div>
    </div>
  );
};
