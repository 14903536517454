import {
  faUser,
  faCircleQuestion,
  faDoorOpen,
} from '@fortawesome/free-solid-svg-icons';
// import { faCoffee, faEy } from '@fortawesome/free-brands-svg-icons';
// import { faCoffee, faEy } from '@fortawesome/free-regular-svg-icons';

export const MENU_LINKS = [
  {
    name: 'Увійти в кабінет',
    icon: faUser,
    link: '/xxx',
  },
  {
    name: 'Про Пласт',
    icon: faCircleQuestion,
    link: '/xdfsdf',
  },
  {
    name: 'Вступ до Пласту',
    icon: faDoorOpen,
    link: '/adfaef',
  },
  {
    name: 'Табори та Вишколи',
    icon: faUser,
    link: '/',
  },
  {
    name: 'Станична Старшина',
    icon: faUser,
    link: '/',
  },
  {
    name: 'Звязатись з Нами',
    icon: faUser,
    link: '/',
  },
];
