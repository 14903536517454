import React, { useReducer } from 'react';

type State = {
  inDarkMode: boolean;
  mobileMenuVisible: boolean;
};

type Action =
  | { type: 'setInDarkMode'; payload: boolean }
  | { type: 'setMobileMenuVisible'; payload: boolean }
  | { type: 'toggleMobileMenuVisible' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setInDarkMode':
      return { ...state, inDarkMode: action.payload };

    case 'setMobileMenuVisible':
      return { ...state, mobileMenuVisible: action.payload };

    case 'toggleMobileMenuVisible':
      return { ...state, mobileMenuVisible: !state.mobileMenuVisible };

    default:
      return { ...state };
  }
}

const initialState: State = {
  inDarkMode: false,
  mobileMenuVisible: false,
};

export const StateContext = React.createContext(initialState);

export const DispatchContext = React.createContext<React.Dispatch<Action>>(
  () => undefined,
);

type Props = {
  children: React.ReactNode;
};

export const GlobalProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   window
  //     .matchMedia('(prefers-color-scheme: dark)')
  //     .addEventListener('change', event => {
  //       dispatch({
  //         type: 'setInDarkMode',
  //         payload: event.matches,
  //       });
  //     });

  //   const isDarkTheme = window.matchMedia(
  //     '(prefers-color-scheme: dark)',
  //   ).matches;

  //   dispatch({
  //     type: 'setInDarkMode',
  //     payload: isDarkTheme,
  //   });
  // }, []);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
};
