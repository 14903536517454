import React from 'react';
import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import style from './Header.module.scss';

import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { Container, Navbar, Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import {
  faSquareFacebook,
  faSquareXTwitter,
  faSquareInstagram,
  faSquareSnapchat,
} from '@fortawesome/free-brands-svg-icons';
// import { faCoffee, faEy } from '@fortawesome/free-regular-svg-icons';
import { DispatchContext, StateContext } from '../GlobalProvider';
import { MENU_LINKS } from '../constants';

export const Header = () => {
  const { inDarkMode, mobileMenuVisible } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const location = useLocation();

  useEffect(() => onLinkClick(), [location]);

  useEffect(() => {
    if (inDarkMode) {
      document.documentElement.classList.add('theme-dark');
      document.documentElement.classList.remove('theme-light');
    } else {
      document.documentElement.classList.remove('theme-dark');
      document.documentElement.classList.add('theme-light');
    }
  }, [inDarkMode]);

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (document.body.clientWidth > 1024) {
        dispatch({ type: 'setMobileMenuVisible', payload: false });
      }
    });
  }, []);

  function onLinkClick() {
    dispatch({ type: 'setMobileMenuVisible', payload: false });
  }

  return (
    <Navbar
      className={classNames('has-shadow is-primary py-2 px-2', style.header, {
        [style.full_page]: mobileMenuVisible,
      })}
    >
      <Container
        className={classNames(style.nav_bar_container, {
          [style.active]: mobileMenuVisible,
        })}
      >
        <Navbar.Brand>
          <a
            className={classNames(
              'navbar-item p-0 mr-auto',
              style.custom_hover,
            )}
            href="\"
          >
            <figure className={classNames('image is-48x48')}>
              <img
                className={style.logo_image}
                src="\imgs\logo.png"
              />
            </figure>
          </a>

          <Button
            renderAs={Link}
            color="primary"
            to="/login"
            className={classNames(
              'has-text-link is-hidden-desktop',
              style.custom_hover,
            )}
          >
            <FontAwesomeIcon
              icon={faHandHoldingDollar}
              size="2x"
            />
          </Button>

          <Navbar.Burger
            className={classNames('mr-2 ml-2', {
              'is-active': mobileMenuVisible,
            })}
            onClick={() => dispatch({ type: 'toggleMobileMenuVisible' })}
          />
        </Navbar.Brand>

        <Navbar.Menu
          className={classNames(style.menu_wndw, {
            'is-active': mobileMenuVisible,
            [style.active]: mobileMenuVisible,
          })}
        >
          {MENU_LINKS.map(menuLink => {
            return (
              <Navbar.Item
                key={uuidv4()}
                className={classNames('my-0 p-1', style.custom_hover)}
              >
                <Link
                  className={style.menu_link}
                  to={menuLink.link}
                  onClick={onLinkClick}
                >
                  <span
                    className={classNames(
                      'icon-text has-text-link',
                      'is-align-items-center',
                      'is-flex-direction-column',
                      style.icon_text,
                    )}
                  >
                    <span className={classNames('icon')}>
                      <FontAwesomeIcon
                        icon={menuLink.icon}
                        className={classNames(style.icon)}
                      />
                    </span>

                    <span>{menuLink.name}</span>
                  </span>
                </Link>
              </Navbar.Item>
            );
          })}

          <Container
            className={classNames(
              'is-flex is-hidden-desktop is-justify-content-center is-flex-grow-1 is-align-items-flex-end',
            )}
          >
            <Link
              className={classNames('navbar-item p-2 mx-6', style.custom_hover)}
              to="https://www.facebook.com/"
            >
              <FontAwesomeIcon
                icon={faSquareFacebook}
                className={classNames('has-text-link', style.icon)}
              />
            </Link>

            <Link
              className={classNames('navbar-item p-2 mx-6', style.custom_hover)}
              to="https://x.com/"
            >
              <FontAwesomeIcon
                icon={faSquareXTwitter}
                className={classNames('has-text-link', style.icon)}
              />
            </Link>

            <Link
              className={classNames('navbar-item p-2 mx-6', style.custom_hover)}
              to="https://web.snapchat.com/"
            >
              <FontAwesomeIcon
                icon={faSquareSnapchat}
                className={classNames('has-text-link', style.icon)}
              />
            </Link>

            <Link
              className={classNames('navbar-item p-2 mx-6', style.custom_hover)}
              to="https://www.instagram.com/"
            >
              <FontAwesomeIcon
                icon={faSquareInstagram}
                className={classNames('has-text-link', style.icon)}
              />
            </Link>
          </Container>
        </Navbar.Menu>

        <Button
          className={classNames(
            'is-hidden-touch p-1 has-background-primary-45',
            style.custom_hover,
          )}
        >
          <Link
            className={classNames(style.menu_link)}
            to={'/'}
            onClick={onLinkClick}
          >
            <span
              className={classNames(
                'icon-text has-text-link',
                'is-align-items-center',
                'is-flex-direction-column',
                style.icon_text,
              )}
            >
              <span className={classNames('icon')}>
                <FontAwesomeIcon
                  icon={faHandHoldingDollar}
                  size="2x"
                  // className={classNames(style.icon)}
                />
              </span>
              <span>Допомога</span>
            </span>
          </Link>
        </Button>
      </Container>
    </Navbar>
  );
};
