import React from 'react';
import classNames from 'classnames';
import style from './FooterElem.module.scss';
import { SubscribeNews } from '../SubscribeNews';
import { Button, Container, Footer } from 'react-bulma-components';
import { useState } from 'react';
import { ContactUsForm } from '../ContactUsForm';

export const FooterElem = () => {
  const [contactUsVisble, setContactUsVisble] = useState(false);

  return (
    <Footer
      className={classNames(
        'is-fixed-bottom has-background-primary',
        style.contaniner,
      )}
    >
      <div className={style.subscribe}>
        <SubscribeNews />
      </div>

      <Container
        className={classNames(
          'columns is-justify-content-center',
          style.contact_us_bt,
        )}
      >
        <Button
          size={'large'}
          className="column is-6-tablet is-5-desktop is-4-widescreen is-3-fullh' has-text-link has-background-primary"
          onClick={() => setContactUsVisble(true)}
        >
          Звязатись з нами
        </Button>
      </Container>

      <div className={style.contact_us_modal}>
        <ContactUsForm
          state={contactUsVisble}
          onChange={setContactUsVisble}
        />
      </div>
    </Footer>
  );
};
