import React from 'react';
import { Button, Hero } from 'react-bulma-components';

export const SubscribeNews = () => {
  return (
    <Hero className="has-background-primary-light">
      <Hero.Header className="mb-2">Підпишіться новини від Пласту</Hero.Header>

      <Hero.Body>
        <p className="px-2 m-0">
          Першими дізнавайтесь про новини та отримуйте запрошення на події та
          читайте статті від кураторів
        </p>

        <div className="columns is-justify-content-center">
          <div className="column is-9-tablet is-6-desktop is-6-widescreen is-6-fullh">
            <form
              method="POST"
              action="https://herotofu.com/start"
              className="is-flex p-2"
            >
              <input
                required
                name="email"
                type="email"
                className="input"
                placeholder="joe.bloggs@example.com"
              />

              <Button
                type="submit"
                className="button px-3 ml-5"
              >
                Підписатись
              </Button>
            </form>
          </div>
        </div>
      </Hero.Body>
    </Hero>
  );
};
