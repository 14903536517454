import React, { useState } from 'react';
import style from './HomePage.module.scss';
import 'react-calendar/dist/Calendar.css';
import {
  Button,
  Container,
  Heading,
  Notification,
  Section,
} from 'react-bulma-components';
import { Link } from 'react-router-dom';
import { MainPhotoSwiper } from '../../components/MainPhotoSwiper';
import { AboutSection } from './AboutSection';
import { LatestPhotoSwiper } from '../../components/LatestPhotoSwiper';
import { NewsSlider } from '../../components/NewsSlider';
import classNames from 'classnames';
import { Calendar } from '../../components/Calendar';

export const HomePage = () => {
  const [announcementVisible, setAnnouncementVisible] = useState(true);

  return (
    <Section
      className={classNames(
        style.main_container,
        'p-2',
        'is-flex',
        'is-flex-direction-column',
      )}
      color="is-primary"
    >
      <Heading
        textAlign="center"
        size={1}
      >
        Пласт Лінденхурст
      </Heading>

      {announcementVisible && (
        <Notification
          color="danger"
          light={true}
          className={classNames('is-flex is-flex-direction-column mx-6 p-5')}
        >
          <button
            className="delete"
            onClick={() => setAnnouncementVisible(false)}
          ></button>

          <Heading size={6}>This site is review only for constraction </Heading>

          <p>
            Primar lorem ipsum dolor sit amet, consectetur adipiscing elit lorem
            ipsum dolor. <strong>Pellentesque risus mi</strong>, tempus quis
            placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet
            fringilla. Nullam gravida purus diam, et dictum{' '}
            <a>felis venenatis</a> efficitur.
          </p>

          <Button
            to="/announcements"
            renderAs={Link}
            className="is-align-self-flex-end"
          >
            Learn More
          </Button>
        </Notification>
      )}

      <Container className={classNames(style.main_photo_swiper)}>
        <MainPhotoSwiper />
      </Container>

      <Container className={style.about_section}>
        <AboutSection />
      </Container>

      <Container className={classNames(style.latest_photo_swiper_container)}>
        <Heading size={2}>Останні Пригоди на Пласті</Heading>

        <div className={classNames(style.latest_photo_swiper)}>
          <LatestPhotoSwiper />
        </div>
      </Container>

      <Container className={classNames(style.news_swiper_container)}>
        <Heading size={2}>Новини</Heading>

        <div className={classNames(style.news_swiper)}>
          <NewsSlider />
        </div>
      </Container>

      <div className={style.calendar_container}>
        <Heading size={2}>Календар</Heading>

        <div className={style.calendar}>
          <Calendar />
        </div>
      </div>
    </Section>
  );
};
